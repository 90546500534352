@import "node_modules/react-modal-video/scss/modal-video.scss";
.decision-guideline-right {
  width: 50px;
  height: 50px;
  background: #1b3f73;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.decision-guideline-area {
  display: flex;
  justify-content: right;
}

.decision-guideline-left p {
  margin-bottom: 0;
  background: #fff;
  font-size: 12px;
  padding: 14px 15px;
  margin-right: 30px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0px 6px 16px 4px rgb(195 195 195 / 10%);
  border: 1px solid #e8e8e8;
  z-index: 2;
}
.decision-guideline-left p:after {
  content: "";
  position: absolute;
  right: -11px;
  top: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #ddd;
  transform: rotate(45deg);
  z-index: 0;
  border-left: none;
  border-bottom: none;
}
.card-body {
  padding: 0;
}
.tutorial-main-wrap {
  background: #f8fbff;
  padding: 50px 0;
  padding-top: 0;
}

.remaining-balance {
  position: relative;
}

.remaining-balance p {
  margin-bottom: 0;
  position: absolute;
  top: -25px;
  width: 100%;
  text-align: center;
  color: #2f80ed;
  /* font-family: "Poppins"; */
  font-size: 13px;
}

.remaining-balance h3 {
  background: #4eb94d;
  margin-bottom: 0;
  padding: 8px 30px;
  color: #fff;
  border-radius: 10px;
  height: 50px;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorial-card.card {
  flex-direction: row;
  border: none;
  background: none;
  box-shadow: none;
  margin-bottom: 0;
}

.tutorial-card.card .card-title.h5 {
  width: 40px;
  height: 40px;
  line-height: 1;
  padding: 0;
  margin: 0;
  text-align: center;
}

.card-title.h5 h3 {
  font-weight: 500;
  background: #4eb94d;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0;
  line-height: 40px;
  font-size: 20px;
}

.tutorial-card-body.card-body {
  padding: 0px 15px;
}

.tutorial-single-item {
  padding: 25px 30px;
  border: 1px solid #e8e8e8;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
  box-shadow: 0px 0px 10px 0px #b6b6b615;
}
tr.estimate-table-header th {
  color: #fff;
}
.tutorial-page-title {
  margin-bottom: 40px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #f8fbff !important;
}

.tutorial-card-body.card-body h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.video-player-inner-wrap {
  width: 100% !important;
  height: 320px !important;
  border-radius: 10px;
  overflow: hidden;
}

.me-auto.decision-navbar-item-wrap.navbar-nav a {
  padding: 10px 30px;
  display: inline-block;
  font-weight: 500;
  /* font-family: "Poppins"; */
  font-size: 14px;
}

.tutorial-page-title.py-5.market-outlook-page {
  margin-bottom: 0;
  // padding-bottom: 15px !important;
  // padding-top: 30px !important;
  // position: relative;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 30px;
  //   background: #f8fbff;
  //   height: 100%;
  //   top: 0;
  //   left: -100%;
  // }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 30px;
  //   background: #f8fbff;
  //   height: 100%;
  //   top: 0;
  //   right: -50%;
  // }
}
.market-outlook-single-item.card {
  border-radius: 10px;
  height: 100%;
  padding: 25px 30px;
  border: none;
  box-shadow: 0px 0px 10px 0px #b6b6b615;
  border: 1px solid #f8f9fa;
}

.tutorial-title-left h2 {
  font-weight: 700;
  color: #1b3f73;
}

.market-outlook-single-items-wrap {
  // margin-bottom: 30px;
  height: 100%;
  box-shadow: 0px 6px 16px 4px #c3c3c31a;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}
.market-outlook-single-items-wrap > * {
  padding-bottom: 10px !important;
}
.market-outlook-content .col-lg-6 {
  margin-bottom: 25px;
}

.market-outlook-wrap {
  background: #f8fbff;
  padding-bottom: 50px;
  // padding-top: 0;
}

.market-outlook-single-item.card .card-title.h5 {
  font-weight: 600;
  /* font-family: "Poppins"; */
}

table.table-borderless.text-center.table thead tr th {
  width: 25%;
  vertical-align: middle;
}

table.table-borderless.text-center.table thead {
  background: #f8f9fa;
}

.demand-home-page-wrap {
  background: #f8fbff;
  padding-bottom: 50px;
}

.single-demand-components {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 6px 16px 4px #c3c3c31a;
  border: 1px solid #e8e8e8;
}
.network-coverage-title h3 {
  color: #1b3f73;
  margin-top: 40px;
}
.estimate-markate-demand-title {
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-markate-demand-table
  .table
  > :not(caption)
  > tr.estimate-table-header {
  border-bottom: 0px solid #ddd;
  text-align: center;
}

.estimate-markate-demand-table
  .table
  > :not(caption)
  > tr.estimate-table-header
  > th:first-child {
  text-align: left;
  padding-left: 20px;
  padding-right: 0;
}
.estimate-markate-demand-table > th {
  text-align: left;
  padding-left: 20px;
  padding-right: 0;
}
.network-title-left.py-3 h5 {
  font-size: 24px;
  font-weight: 600;
}

.estimate-markate-demand-table .table > :not(caption) > tr {
  border-bottom: 2px solid #dddddd60;
  height: 60px;
  vertical-align: middle;
  text-align: center;
}

.estimate-markate-demand-table .table > :not(caption) > tr:last-child {
  border: none;
}

.estimate-markate-demand-table .table > :not(caption) > tr > td:first-child {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.ct-series-c .ct-bar {
  stroke: red;
  stroke-width: 15px;
}

.ct-series-a .ct-bar,
.ct-series-a .ct-point {
  stroke: #1b3f73;
  stroke-width: 15px;
}

.ct-series-b .ct-bar,
.ct-series-b .ct-point {
  stroke: #eb2027;
  stroke-width: 15px;
}

foreignObject {
  text-align: center;
}

.ct-series-a .ct-line {
  stroke: #eb2027;
  stroke-width: 5px;
  fill: #ffffff00;
}

.ct-series-b .ct-line {
  stroke: #1b3f73;
  stroke-width: 5px;
  fill: #ffffff00;
}

.ct-series-c .ct-line {
  stroke: #f2994a;
  stroke-width: 5px;
  fill: #ffffff00;
}

.ct-series-d .ct-line {
  stroke: #219653;
  stroke-width: 5px;
  fill: #ffffff00;
}

.estimate-markate-demand-title h3 {
  font-size: 20px;
  color: #1b3f73;
  // text-transform: capitalize;
}
.ct-chart .ct-label {
  text-transform: capitalize;
}
.network-title-right-btn {
  background: none !important;
  border: none;
  padding: 0;
}

.network-title-right-btn span {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 2px;
  border-radius: 50%;
  border: 1px solid #1b3f73;
}

.dropdown-toggle::after {
  display: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.text-orange {
  color: unset;
}

.input-field {
  width: 100px;
  overflow: hidden;
  margin: auto;
  text-align: right;
}

.input-field input {
  width: 100%;
  border: 2px solid #2f80ed !important;
  border-radius: 10px;
  color: #2f80ed;
  text-align: right;
  padding: 2px 5px;
}

.input-field input::placeholder {
  color: #2f80ed;
  text-align: right;
  font-size: 14px;
}

.input-field input:focus {
  outline: #2f80ed;
  background: #2f81ed05;
  color: #2f80ed;
}

.input-field input::-webkit-outer-spin-button,
.input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-field input[type="number"] {
  -moz-appearance: textfield;
}

.market-share-panning-wrap {
  background: #fff;
  margin-top: 40px;
  border-radius: 10px;
  /* padding-bottom: 10px; */
  /* box-shadow: 0px 0px 10px 0px #b6b6b615;
  border: 1px solid #f8f9fa; */
}
.dropdown-menu.show {
  position: absolute !important;
  left: unset !important;
  right: 0 !important;
  z-index: 999999;
  background: #edf6ff;
  box-shadow: 0px 3px 15px 6px #5f5f5f14;
  border-radius: 10px;
}
.single-demand-components.comp-1 {
  box-shadow: unset;
  border: unset;
}
.market-planning-right-btn {
  width: 30px;
  height: 30px;
  line-height: 1;
  border-radius: 50%;
  background: #1b3f73;
  border: none;
}

.network-title-left p {
  margin-bottom: 0;
}

.estimate-markate-demand-title.market-planing-page {
  align-items: end;
}

select.market-share-planning-select-network.form-select {
  width: 140px;
  margin: auto;
  border: 2px solid #c5c5c5;
  padding: 7px 15px;
  font-size: 14px;
  color: #1b3f73;
  border-radius: 5px;
  font-weight: 500;
}
td {
  font-size: 14px;
  text-transform: capitalize;
}
th {
  font-size: 14px;
}
tr.estimate-table-header.market-planing th {
  width: 14% !important;
  padding: 10px 25px;
  font-weight: 500;
  /* font-family: "Poppins"; */
}
a.navbar__link.nav-link.navbar__link--active {
  font-weight: 700;
  color: #1b3f73;
}
.production-page-tabs .nav-tabs .nav-item.show .nav-link,
.production-page-tabs .nav-tabs .nav-link.active {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #1b3f73;
  background: none;
  color: #1b3f73;
  font-weight: 700;
  border-radius: 0px !important;
}

table.table-borderless.text-center.table thead tr th {
  padding: 10px 10px;
  font-weight: 500;
  /* font-family: "Poppins"; */
  font-size: 14px;
  width: 16%;
  color: #fff;
}
.network-overage-area.comp-3 .market-share-panning-wrap {
  min-height: 450px;
}

.nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}

.nav-tabs .nav-link:focus {
  border: 1px solid transparent;
  outline: none;
}

.inventory-main-page {
  background: #f8fbff;
  padding-bottom: 50px;
}

.estimate-markate-demand-wrap {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 6px 16px 4px #c3c3c31a;
  border: 1px solid #e8e8e8;
  // overflow: hidden;
}
.production-requiarment-height {
  height: 90%;
  margin-bottom: 30px;
  .estimate-markate-demand-wrap {
    height: 100%;
    tbody {
      .Production.-requirment-body-main-wrap p {
        padding-top: 10%;
      }
    }
  }
}
.r-and-d-top-data-tech-content- .estimate-markate-demand-wrap {
  margin-bottom: 30px;
}
div#uncontrolled-tab-example-tabpane-inventory .estimate-markate-demand-wrap {
  background: #fff;
  border-radius: 10px;
  margin: 40px 0;
  box-shadow: 0px 6px 16px 4px #c3c3c31a;
  border: 1px solid #e8e8e8;
}

div#uncontrolled-tab-example-tabpane-planning .estimate-markate-demand-wrap {
  margin-bottom: 30px;
}
.r-and-d-top-data-tech-content .card-body {
  background: #f8fbff;
}
tr.estimate-table-header.inventory-1 th {
  width: 14% !important;
}

.planing-tab-btn-wrap {
  text-align: right;
  padding: 25px;
}

button.planing-btn.btn.btn-primary {
  background: #1b3f73;
  border-radius: 10px;
  border: none;
  padding: 8px 25px;
}

select.market-share-planning-select-network.product-requirment.form-select {
  width: 150px;
  padding: 5px 10px;
}

button.planing-btn.planing-btn2.btn.btn-primary {
  color: #1b3f73;
  background: #fff;
  border: 1px solid #1b3f73;
}

tr.estimate-table-header.in-house-manufacturing th {
  width: 33% !important;
}

.in-house-page
  .table
  > :not(caption)
  > tr.estimate-table-header
  > th:first-child {
  width: 60% !important;
}
tr.estimate-table-header.inventory-1.in-house-manufacturing-data th:last-child {
  width: 22% !important;
}
.nav-tabs .nav-link {
  color: #828282;
}

.r-and-d-data-main-wrap {
  background: #f8fbff;
  padding-bottom: 40px;
}

.r-and-d-inner-body {
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 6px 16px 4px #c3c3c31a;
  border: 1px solid #e8e8e8;
}
.decision-checklist-inner-body--- {
  background: #fff;
}
.show-all-demand-components .market-share-panning-wrap {
  box-shadow: none;
  border: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
}

.card-header p {
  margin-bottom: 0px;
}

img.card-img-top.r-and-d-tech-image {
  width: 130px;
  margin: 25px auto;
  display: inherit;
}

img.card-img-top.r-and-d-tech-image-header {
  width: 25px;
}

.tech-single-data ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
}

.tech-single-data ul li {
  list-style: none;
  width: 20%;
  text-align: center;
  position: relative;
}

.tech-single-data ul li:first-child {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tech-single-data.top-tech {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.tech-single-data
  ul
  li:first-child
  > select.market-share-planning-select-tech.form-select {
  width: 110px;
  position: relative;
}

tr.tech-single-data.bottom-tech td select {
  width: 90%;
  padding: 8px 15px;
  margin-left: auto;
}

.tech-data-item-wrap table.table tr {
  border-bottom: 0;
  border-style: hidden;
  margin-bottom: 16px;
}

.tech-data-item-wrap {
  text-align: center;
}

.tech-single-data ul li select {
  background-color: #ececec;
}

button.planing-btn.rnd-tech-page.btn.btn-primary {
  margin: 50px 0;
}

.text-right {
  text-align: right;
  padding: 20px;
  padding-bottom: 0;
}

table.table-borderless.rnd-page.text-center.table thead tr th {
  width: unset;
  vertical-align: middle;
}

.network-title-left.rnd-page.py-3 {
  position: relative;
  left: -14px;
}

table.table-borderless.rnd-page.table tbody tr td:nth-child(2) {
  text-align: center;
}
table.table-borderless.rnd-page-.text-center-.rnd-main-page-.table.table-striped
  select.market-share-planning-select-network.product-requirment.form-select {
  width: 120px !important;
  padding: 5px 10px;
  border-radius: 10px;
}

select.market-share-planning-select-network.product-requirment.form-select {
  width: 160px;
  padding: 7px 15px;
  border-radius: 10px;
}

.network-title-left.rnd-page h3 {
  font-size: 24px;
  color: #1b3f73;
}

table.table-borderless.rnd-page.text-center.table thead tr th:nth-child(2) {
  width: 50%;
  padding: 0;
}

table.table-borderless.rnd-page.text-center.table tbody tr td {
  font-size: 13px;
}

.network-title-left.marketng-page {
  display: flex;
  align-items: center;
}

.network-title-left.marketng-page h3 {
  margin-right: 15px;
}

.network-title-left.marketng-page button {
  margin: 0px 7px;
  padding: 3px 20px;
  border: 1px solid #1b3f73;
  background: #fff;
  border-radius: 5px;
  color: #1b3f73;
  font-weight: 500;
  /* font-family: "Poppins"; */
  font-size: 14px;
}

tr.estimate-table-header.inventory-2 th select {
  margin-right: auto !important;
  width: 140px !important;
  margin: unset;
  float: right;
}

tr.estimate-table-header.inventory-2 th:last-child {
  padding-right: 25px !important;
}

table.table-borderless.text-center.margin-calculation.table tr td:last-child {
  text-align: right;
  padding-right: 25px;
}

table.table-borderless.text-center.margin-calculation.table tbody tr td {
  padding: 15px 25px;
  padding-top: 25px;
}

.decision-checklist-content- {
  background: #fff;
}

.r-and-d-data-main-wrap
  table.table-borderless.rnd-page.text-center.table
  thead
  tr
  th:nth-child(2) {
  width: 50%;
  padding: 0;
  text-align: left;
}

.table-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.decision-checklist-content--- tbody {
  border-top: 1px solid #ddd !important;
}

.checklist-page-inner-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.select-checklist-background-color {
  display: flex;
  align-items: center;
}

.select-checklist-background-color p {
  margin-bottom: 0;
  margin-left: 50px;
  position: relative;
}

.select-checklist-background-color p span {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: absolute;
  left: -20px;
  top: 24%;
}

.select-checklist-background-color h4 {
  margin-left: 30px;
  margin-bottom: 0;
}

p.team-bg-color span {
  background: #f2f2f2;
}

p.team-bg-color span {
  background: #f2f2f2;
}

p.current-bg-color span {
  background: #c8d9f2;
}

.decision-checklist-content--- tbody tr td {
  width: 14%;
}

.decision-checklist-content--- tbody tr td:first-child {
  width: 16%;
}

td.team-bg-color {
  background: #f2f2f2;
}

/* a.navbar__link.nav-link.navbar__link--active {
  border-bottom: 2px solid #1b3f73;
} */

button.planing-btn.rnd-tech-page.btn.btn-primary.decision-page {
  margin: 0 0;
  padding: 5px 0;
}

label.planing-btn.planing-btn2.btn.btn-primary.import-file {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 0;
  padding: 5px 20px;
  border-color: #1b3f73;
  color: #1b3f73;
  font-weight: 600;
  /* font-family: "Poppins"; */
}

.decision-checklist-content--- {
  text-align: center;
}

.decision-checklist-content--- tbody tr td:first-child {
  text-align: left;
}

.decision-checklist-content--- .table > tbody {
  vertical-align: middle;
}

.network-title-right.round-btn button {
  width: 20px;
  height: 20px;
  background: #1b3f73;

  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  padding: 0;
}

.network-title-right.round-btn p {
  width: 30px;
  height: 30px;
  background: #1b3f73;
  border-radius: 50%;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}

.dropdown-toggle::after {
  display: none !important;
}

.network-title-right button {
  border: none;
}

/* .single-demand-components.comp-1 {
  padding: 10px 0;
} */

.single-demand-components table {
  border: none !important;
}

.estimate-markate-demand-table table {
  border: none;
  margin-bottom: 0;
}
.single-demand-components.comp-2 {
  min-height: 440px;
}

.r-and-d-top-data-tech-content .card-header p {
  font-weight: 700;
  color: #1b3f73;
  /* font-family: "Poppins"; */
  font-size: 24px;
}

.tech-single-data.top-tech ul li {
  font-weight: 500;
  /* font-family: "Poppins"; */
  color: #1b3f73;
}

.r-and-d-top-data-tech-content div .card {
  border-radius: 10px;
}

.estimate-markate-demand-table .table > :not(caption) > tr td img {
  display: inline-block;
  height: 20px;
  padding-right: 10px;
}

button.default-site-color-btn.btn.btn-primary {
  border-radius: 10px;
  background: #1b3f73;
  border: none;
  padding: 8px 20px;
}

.decision-checklist-content--- > table > * > * > * {
  border: 1px solid #e0e0e0 !important;
}

.decision-checklist-content--- > table > * > * > *:nth-child(2) {
  background: #f2f2f2;
}

.decision-checklist-content--- > table > * > * > *:nth-child(3) {
  background: #c8d9f2;
}

.decision-checklist-content--- > .table > :not(caption) > * > * {
  padding: 20px 20px;
}

.single-round-checklist-table-text select {
  background: #1b3f73;
  color: #fff !important;
}

.checklist-checkbox-btn input {
  width: 22px;
  height: 22px;
}

:checked {
  color: red;
}

.network-coverage-title--- button {
  border: 1px solid #1b3f73;
  margin-right: 10px;
  padding: 0px 30px;
  border-radius: 10px;
  height: 40px;
  background: #fff;
  font-weight: 500;
  /* font-family: "Poppins"; */
  color: #1b3f73;
}

.income-statemant.table > :not(:first-child) {
  border-top: 2px solid #dddddd7a;
}

.estimate-markate-demand-table .table > :not(caption) > tr:last-child {
  border: none;
  /* background: #f8f9fa; */
}

table.table.table-bordered.income-statemant .table > :not(caption) > * > * {
  padding: 20px 20px;
}

.estimate-markate-demand-title- {
  display: flex;
  align-items: center;
}

.estimate-markate-demand-title- {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.network-title-left.marketng-page- h3 {
  margin-bottom: 0;
  margin-right: 30px;
}

.network-title-left.marketng-page- {
  margin: 40px 0;
}
.network-title-right.round-btn.result-page select {
  color: #fff;
  background: #1b3f73;
}

.submit-color button {
  border: 1px solid green;
  padding: 8px 25px;
  color: green;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  background: #fff !important;
  width: 155px;
}
.not-submit-color button {
  border: 1px solid red;
  padding: 8px 20px;
  color: red !important;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  background: #fff !important;
  width: 155px;
}
.not-submit-color button {
  font-size: 14px;
}
.estimate-markate-demand-table.result-page
  .table
  > :not(caption)
  > tr:last-child {
  background: none;
  border-bottom: none !important;
}
.estimate-markate-demand-table.result-page button.Evulation {
  border: none;
  background: #1b3f73;
  width: 155px;
  padding: 8px 25px;
  display: inline-block;
  color: #fff;
  /* margin-bottom: 50px; */
  margin-top: 30px;
  border-radius: 5px;
}
.estimate-markate-demand-table.result-page .table > :not(caption) > tr > td {
  padding: 20px 25px;
  color: #1b3f73;
  font-weight: 600;
}

.estimate-markate-demand-table.result-page {
  margin-bottom: 50px;
  background: #ffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 20px 5px #1b3f7310;
  border: 1px solid #1b3f732b;
}

/* .network-title-right.round-btn.result-page {
  margin-right: 9%;
} */
.network-title-right.round-btn.result-page select {
  width: 155px;
  text-align: center;
  padding: 8px 25px;
}
.market-outlook-single-item.Demand.card {
  background: #e9f2ff;
  border-color: #0180f5;
}
.market-outlook-single-item.Demand.card h5 {
  color: #1087f6;
}
.market-outlook-single-item-item-header h5 {
  font-weight: 600;
}

.market-outlook-single-item-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.market-outlook-single-item.Cost.card {
  background: #ffeff0;
  border-color: #eb2027;
}

.market-outlook-single-item.Cost.card h5 {
  color: #eb2027;
}

.market-outlook-single-item.Finance.card {
  background: #e9f2ff;
  border-color: #0180f5;
}
.market-outlook-single-item.Finance.card h5 {
  color: #0180f5;
}
:checked {
  color: green;
}

/* ----------------------------------------- */
button.btn.bhjnkxhcvbdsniuljnm {
  background: #1b3f73;
  color: #fff;
}

tr.estimate-table-header {
  background: #1b3f73 !important;
}

tr.estimate-table-header > th > button {
  color: #fff !important;
}
table.table-borderless.text-center.table.table-striped td.hkdfjhb {
  --bs-table-accent-bg: #ffffff !important;
  position: relative;
  z-index: 99;
}
td.hkdfjhb:after {
  content: "";
  position: absolute;
  background: #fff;
  left: 0;
  width: 100%;
  height: 50%;
  top: 0;
  z-index: -1;
}
td.hkdfjhb:before {
  content: "";
  position: absolute;
  background: #fff;
  left: 0;
  width: 100%;
  height: 50%;
  bottom: 0;
  z-index: -1;
}
.network-title-left.rnd-page.py-3 h3 {
  color: #fff;
  padding-left: 15px;
}
.tutorial-title-right > * > .col-lg-6:first-child {
  width: 60%;
}
.tutorial-title-right > * > .col-lg-6:last-child {
  width: 40%;
}
button.market-share-planning-select-tech.btn.btn-primary {
  width: fit-content;
  margin: 25px auto;
  padding: 8px 20px;
  background: #0b5ed7;
}

td > button {
  border: 2px solid #c5c5c5;
  padding: 6px 15px;
  font-size: 14px;
  color: #1b3f73;
  border-radius: 5px;
  background: #fff;
}
td > * > button {
  border: 2px solid #c5c5c5;
  padding: 4px 10px;
  font-size: 14px;
  color: #1b3f73;
  border-radius: 5px;
  background: #fff;
}

table.table-borderless.rnd-page.text-center.rnd-main-page.table.table-striped
  .form-select {
  width: 119px;
  padding: 5px 10px;
  border-radius: 10px;
}
table.table-borderless.rnd-page.text-center.table.table-striped
  select.market-share-planning-select-network.product-requirment.form-select {
  width: 120px;
  padding: 5px 10px;
  border-radius: 10px;
  padding-left: 10px;
}

/* table.table-borderless.rnd-page-.text-center-.rnd-main-page-.table.table-striped
  tbody
  tr:first-child
  td {
  width: unset !important;
} */
table.table-borderless.rnd-page-.text-center-.rnd-main-page-.table.table-striped
  td
  > img {
  display: none;
}

.estimate-markate-demand-table h6 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.marketing-table-header-data-inner {
  display: flex;
  align-items: center;
  gap: 10px;
}
.marketing-table-header-data-inner .spinner-border.text-light {
  width: 18px;
  height: 18px;
}
/* ======================================================= */
.market-share-panning-wrap.income-real-market-share {
  min-height: unset !important;
}

tbody.active-game-table-data-body.ghjhgffghbnvcbnjkk tr td {
  padding-bottom: 10px !important;
}
.priorities-countries {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  padding-left: 0;
  gap: 10px;
  text-align: center;
}
.priorities-countries > span {
  width: fit-content;
  /* border: 1px solid #c5c5c5; */
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #1b3f73;
}
.priorities-countries > input {
  width: 100%;
  border: 1px solid #2f80ed;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #1b3f73;
}

.estimate-markate-demand-table.priorities-components table thead > tr > th {
  width: 25% !important;
}
.estimate-markate-demand-table.priorities-components table thead > tr > {
  th {
    &:first-child {
      width: 20% !important;
    }
    &:last-child {
      width: 30% !important;
    }
  }
}

.estimate-markate-demand-table.priorities-components {
  .input-field.market-share-input {
    display: flex;
    input {
      width: 100%;
      height: 28px;
      border-radius: 5px 0px 0px 5px;
    }
    span {
      width: 50px;
      height: 28px;
      border-radius: 0px 5px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }
}
.calibax-pagination {
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1b3f73;
    border-color: #1b3f73;
  }
  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    color: #fff;
    background-color: #1b3f73;
    border-color: #1b3f73;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
  }
}
.decision-guideline-area-right > button {
  border: none;
  width: 100%;
  padding: 12px 30px;
  margin-top: 15px;
  background: #66bb6a;
  color: #fff;
}

//===================================

.decision-guideline-area {
  display: flex;
  justify-content: right;
  position: relative;
  z-index: 999;
  .decision-guideline-right {
    width: 50px;
    height: 50px;
    background: #1b3f73;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    z-index: 999;
  }
}
.decision-guideline-area.overlay-show:after {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #00000080;
}
.decision-guideline-left {
  width: 95%;
}

.estimate-markate-demand-table .table tr {
  &.usa-area td {
    --bs-table-accent-bg: #f8dee0 !important;
  }
  &.southeast-asia td {
    --bs-table-accent-bg: #deffde !important;
  }
  &.middle-east td {
    --bs-table-accent-bg: #fffadc !important;
  }
}
thead.fixed-table-header-top {
  position: sticky;
  top: 140px;
  z-index: 8;
}
thead.inventory-table-row-text-color td b {
  color: #2f80ed;
}
.estimate-markate-demand-title.rnd-header {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.network-title-right.round-btn.bg-white-table-data button {
  background: #e8a016;
  color: #1b3f73;
}
.network-title-left.rnd-page.header-flex.py-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tutorial-title-left.research-and-development--title {
  display: flex;
  align-items: center;
  gap: 15px;
  h2 {
    margin-bottom: 0;
  }
}
