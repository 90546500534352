.empty-course-main-wrap {
  padding: 50px 0;
  /* background: #E5E5E5; */
}

.empty-course-single-item {
  background: #fff;
  border-radius: 10px;
  padding: 30px 0;
  padding-top: 0;
  overflow: hidden;
  margin-bottom: 25px;
}

.empty-course-content-left table {
  width: 100%;
  text-align: center;
}

.empty-course-content-left table thead {
  padding: 15px 5px;
  display: block;
  background: #f8f9fa;
}

.empty-course-content-left table thead tr th {
  color: #1b3f73;
  font-size: 16px;
}

.empty-course-content-left table tbody {
  padding: 20px 0;
  display: block;
}

.empty-course-title {
  background: #f8f9fa;
  padding: 20px 50px;
}

.empty-course-title h2 {
  font-size: 24px;
  color: #1b3f73;
}

.empty-course-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
}

button.all-submit-btn.btn.btn-primary {
  background: #1b3f73;

  padding: 8px 30px;
}

i.text-make-bold-asdf {
  font-size: 18px !important;
  font-weight: 600;
  display: inline-block;
}

button.all-submit-btn-2.btn.btn-primary {
  background: #fff;
  border: 2px solid #1b3f73;
  padding: 8px 25px;
  color: #1b3f73;
}

.empty-course-single-team-wrap {
  width: 100%;
  margin-bottom: 40px;
}

.empty-course-single-team {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;
}

.empty-course-content.login-page {
  padding: 2% 10%;
  display: block;
}

.empty-course-single-team-title {
  padding-bottom: 0px;
  border-bottom: 2px solid #ddd;
  margin-bottom: 7px;
}

.empty-course-single-team-names > i {
  margin-bottom: 20px;
  display: block;
  color: #95989b;
}

.active-group-team.card {
  flex-direction: unset;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-radius: unset;
  border-left: unset;
  border-top: unset;
  border-right: unset;
  margin-bottom: 15px;
  box-shadow: unset !important;
}

img.active-group-card-image {
  width: 40px;
  height: 40px;
  background-color: #ddd;
  border-radius: 50%;
  /* visibility: hidden; */
}
.active-group-team.card {
  padding: 2px 6%;
}
.empty-course-content.login-active {
  padding: 0% 7%;
  width: 100%;
  justify-content: space-between;
  display: block;
}
.student-home-welcome-area-button-wrap {
  display: flex;
  gap: 15px;
}
.course-play-btn-main {
  background: #fff;
  padding: 10px 28px;
  display: inline-flex;
  border-radius: 5px;
  color: #1b3f73;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: 10px;
  border: 1px solid #fff;
}
.active-group-team.card h4 {
  font-size: 18px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.active-group-team.card i {
  font-size: 14px;
}
.active-group-team.card p {
  margin-bottom: 0;
  font-size: 14px;
}
.empty-course-content.schedule-group-wrap {
  display: block;
  padding: 0% 0%;
  width: 100%;
  min-height: 300px;
  /* overflow-y: scroll; */
}

.active-group-team.schedule-group.card {
  margin-bottom: 0px;
  padding: 10px 30px;
}

.active-group-team.schedule-group.card .card-body {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
}

.empty-course-single-item.student-profile {
  width: 100%;
  height: 94%;
}

.student-login-profile {
  display: flex;
  align-items: center;
  height: 100%;
}
.student-profile .empty-course-content-left {
  height: 100%;
  padding: 10%;
}
.empty-course-single-item.student-profile.entry-screen-student {
  background: #1b3f73;
}

.student-login-profile h2 {
  color: #ffff;
}

.student-login-profile p {
  color: #fff;
  line-height: 28px;
  font-size: 18px;
}
.empty-course-content.student-login-page {
  padding: 3%;
}

.empty-course-content.student-login-page .active-group-team.card {
  border-bottom: 0;
}

.empty-course-content.student-login-page .active-group-team.card .card-body {
  padding: 0 10px;
}
.team-bg-green {
  background: #66bb6a !important;
}
.team-bg-red {
  background: #fe1743 !important;
}
.team-bg-blue {
  background: #438afe !important;
}
.team-bg-yellow {
  background: #f9a825 !important;
}
.team-bg-orange {
  background: #f5511e !important;
}
.team-bg-skyblue {
  background: #7c4dff !important;
}
.card-body-main-data-kgjnfg.card-body {
  padding-left: 0 !important;
}
.card-body-main-data-kgjnfg.card-body {
  padding-left: 0 !important;
}

.team-group-content-jnfjgdf {
  padding-left: 20px;
}

.team-group-content-jnfjgdf > p {
  margin-bottom: 8px;
}

.card-body-main-data-kgjnfg.card-body > i {
  font-size: 16px !important;
}

.password-form-area-show {
  position: relative;
}

.password-form-area-show > i {
  position: absolute;
  top: 42px;
  right: 15px;
}

.notification-icon-wrap {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #1b3f73;
  justify-content: center;
  border-radius: 50%;
}
