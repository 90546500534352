//==================================

.requirement-table-main-area-card-wrap {
  min-height: 60vh;
  .estimate-markate-demand-wrap.requirement-table-main-area {
    margin-top: 50px;
    overflow: hidden;
    border: 1px solid #d7d7d7;
    tbody.requirement-table-body-content {
      tr {
        td {
          text-align: left;
        }
      }
    }
  }
}
