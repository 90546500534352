.ms-auto.header-top-content-right.navbar-nav span {
  padding-left: 10px;
}

.main-navbar-area-wrap {
  background: #fff;
}

.main-navbar-area-wrap a {
  color: #1b3f73 !important;
  font-size: 14px;
}

.main-navbar-area-wrap .main-navbar-left a {
  padding-left: 0px !important;
  /* padding-right: 40px !important; */
}
.ms-auto.main-navbar-left.navbar-nav {
  gap: 20px;
}
.navbar-profile-link a {
  color: #000 !important;
}

.header-item {
  height: 40px !important;
}

.header-top-main-wrap .bg-light {
  background-color: #fff !important;
}
.ms-auto.main-navbar-left.main-navbar.navbar-nav
  a.navbar__link.nav-link.main-header.navbar__link--active {
  position: relative;
}
.ms-auto.main-navbar-left.main-navbar.navbar-nav
  a.navbar__link.nav-link.main-header.navbar__link--active::after {
  content: "";
  width: 90%;
  height: 2px;
  background: #1b3f73;
  position: absolute;
  left: 0;
  bottom: 0;
}

.me-auto.decision-navbar-item-wrap.navbar-nav
  > a.navbar_link.nav-link.navbar_link--active {
  position: relative;
}
.me-auto.decision-navbar-item-wrap.navbar-nav
  > a.navbar_link.nav-link.navbar_link--active::after {
  content: "";
  width: 90%;
  height: 2px;
  background: #1b3f73;
  position: absolute;
  left: 0;
  bottom: 0;
}
.me-auto.decision-navbar-item-wrap.navbar-nav
  > a.navbar__link.nav-link.navbar__link--active:after {
  position: absolute;
  width: 100%;
  height: 2px;
  content: "";
  background: #1b3f73;
  left: 0;
  bottom: 0;
}
.me-auto.decision-navbar-item-wrap.navbar-nav a {
  position: relative;
}

.schedule-time-top.result-summary-header-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.schedule-time-top.result-summary-header-item button {
  background: #1b3f73;
  color: #fff;
  border: none;
  border-radius: 10px !important;
  padding: 8px 20px;
}
