//
// coming-soon.scss
//

.counter-number {
  font-size: 32px;
  font-weight: $fw-medium;
  text-align: center;
  color: $primary;
  span {
    font-size: 16px;
    font-weight: $font-weight-normal;
    display: block;
    padding-top: 7px;
    color: $text-muted;
  }
}

.coming-box {
  float: left;
  width: 21%;
  padding: 14px 7px;
  margin: 0px calc($grid-gutter-width/2) $grid-gutter-width
    calc($grid-gutter-width/2);
  background-color: $card-bg;
  border-radius: $card-inner-border-radius;
  box-shadow: $box-shadow;
}

@media (max-width: 991.98px) {
  .coming-box {
    width: 40%;
  }
}
