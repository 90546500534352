.empty-universe-main-wrap {
  padding: 70px 0;
  /* background: #E5E5E5; */
}

.empty-universe-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.universe-open-modal-btn.btn {
  background: #1b3f73;
  color: #fff;
  padding: 10px 25px;
}

.empty-universe-top-left h3 {
  margin-bottom: 0;
  font-size: 24px;
  color: #1b3f73;
  font-weight: 600;
}
.input-file-wrap label {
  margin-bottom: 0;
  color: #6e7c91;
  font-weight: 600;
  padding-left: 15px;
  font-size: 14px;
}
.create-universe-header {
  padding: 0px 0;
  margin-bottom: 20px;
}

.create-universe-header h3 {
  font-size: 24px;
  color: #1b3f73;
}

.react-responsive-modal-modal {
  width: 500px;
  border-radius: 10px;
  padding: 2%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.react-responsive-modal-closeButton {
  top: 30px;
  right: 30px;
  border: 2px solid;
  border-radius: 50%;
}

button.add-universe-submit-btn.btn.btn-primary {
  width: 100%;
  background: #1b3f73;
  margin-top: 15px;
}

button.add-universe-submit-btn2.btn.btn-primary {
  width: 100%;
  border: 1px solid #1b3f73;
  color: #1b3f73;
  background: #fff;
  margin-top: 15px;
}

button.create-universe-body-btn {
  border: none;
  background: none;
  color: #556ee6;
}

.empty-universe-bottom {
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.empty-universe-bottom-inner img {
  display: block;
  margin-bottom: 30px;
}

.empty-universe-bottom-inner p {
  line-height: 24px;
  color: #9fa3a6;
}

.react-responsive-modal-overlay {
  background: #1b3f7390;
}

.universe-items-inner {
  padding: 30px 0;
}

.single-universe-item {
  background: #fff;
  border-radius: 10px;
  padding: 30px 0;
  padding-top: 0;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #b6b6b615;
  border: 1px solid #f8f9fa;
}

.single-universe-item i {
  color: #828282;
}

.single-universe-item-title {
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 10px;
  background: #1b3f73;
}
.single-universe-item-title h4 {
  color: #fff;
  padding: 0 30px;
  padding-top: 11px;
}
a.add-universe-submit-btn.btn {
  padding: 10px 30px;
  display: inline-block;
  background: #1b3f73;
  margin-top: 15px;
  color: #fff;
}
.single-universe-item-body {
  padding: 30px;
}
.edit-view-universe-item-btn {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.view-universe-main-wrap {
  padding: 50px 0;
  /* background: #e5e5e5; */
}

.view-universe-header {
  margin-bottom: 30px;
}

.view-universe-header h3 {
  font-size: 36px;
  color: #1b3f73;
  font-weight: 600;
}
.view-universe-inner-wrap {
  background: #fff;
  /* padding: 30px; */
  overflow: hidden;
  border-radius: 10px;
}

.view-universe-title {
  /* border-bottom: 2px solid #ddd; */
  margin-bottom: 25px;
  padding-bottom: 10px;
  background: #1b3f73;
}

.view-universe-title h4 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding: 0 30px;
  padding-top: 15px;
}
.view-universe-footer {
  padding: 30px;
}
.view-universe-body {
  border-bottom: 2px solid #ddd;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.empty-universe-bottom.universe-team-page {
  /* height: 100vh; */
  display: block;
  margin-top: 30px;
}

a.add-universe-submit-btn.btn.view-universe-edit-btn {
  margin-top: 0;
}

button.add-team-single-person.btn.btn-primary {
  width: 100%;
  font-size: 30px;
  padding: 0px 10px;
  background: #1b3f73;
}

.add-team-single-part {
  padding: 20px;
  box-shadow: 0px 0px 13px 2px #ddddddb3;
  border-radius: 10px;
  margin-bottom: 25px;
  height: 100%;
}

#snapshot {
  text-align: center;
  width: 100%;
}

#snapshot > div {
  width: 100%;
}

img.file-input-image {
  width: 100%;
  display: block;
  height: 100%;
  margin-top: 25px;
}

.add-team-single-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #ddd;
}

.card-header {
  background: none;
}

.view-single-team-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.view-single-team-item-btn button {
  border: 1px solid #1b3f73;
  background: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #1b3f73;
  text-transform: uppercase;
  font-weight: 700;
}
button.select-captain.captain {
  background: #1b3f73;
  color: #fff;
}
.view-single-team-item-btn {
  display: flex;
  width: 50px;
}
.view-single-team-item > p {
  margin-bottom: 0;
  font-size: 14px;
  overflow-x: scroll;
  width: calc(100% - 60px);
  text-align: left;
}
.view-single-team-item > p::-webkit-scrollbar {
  height: 4px;
}
/* Track */
.view-single-team-item > p::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle on hover */
.view-single-team-item > p:hover::-webkit-scrollbar-thumb {
  background: #555;
  cursor: pointer;
}

.form-control::placeholder {
  font-size: 14px;
}
button.team-person-delete-btn {
  border: none;
  margin-left: 5px;
  color: red;
}

button.team-person-delete-btn img {
  width: 22px;
  height: 22px;
}

a.team-single-edit-btn i {
  color: #95989b;
  font-size: 12px;
}

a.team-single-save-btn {
  color: #27ae60;
  font-weight: 600;
}

.single-team-color-red h4 {
  background: #eb2027;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 7px;
}

.single-team-color-yellow h4 {
  background: #f2c94c;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 7px;
}

.single-team-color-green h4 {
  background: #219653;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 7px;
}

.single-team-color-orange h4 {
  background: #f2994a;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 7px;
}

.single-team-color-blue h4 {
  background: #2f80ed;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 7px;
}

.empty-universe-bottom-global-challenge {
  margin-top: 40px;
}

.view-global-challenge-pagination {
  text-align: right;
  margin-top: 24px;
}

.view-global-challenge-pagination a {
  display: inline-block;
  padding: 12px 30px;
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

a.next-pagination-btn {
  background: #1b3f73;
  color: #fff;
  margin-left: 15px;
}

.create-universe-header.card-header {
  background: #fff;
  padding: 0;
  margin-bottom: 40px;
}

button.react-datetime-picker__clear-button.react-datetime-picker__button {
  display: none;
}

.react-datetime-picker {
  width: 100%;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.react-datetime-picker__wrapper {
  border: none !important;
  width: 100%;
}
.ms-auto.header-top-content-right9 {
  margin-right: 20px;
}
.input-file-wrap {
  display: inline-block;
  background: #f3f8ff;
  width: 100%;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
  border: 2px dashed #ddd;
  position: relative;
}
.input-file-wrap > label > img {
  position: absolute;
  left: 10px;
  top: 10px;
}
input[type="file"] {
  display: none;
}
button.delete-student {
  border: none;
  margin-left: 10px;
}
/* ========== */
.team_Blue {
  border: 1px solid blue;
}
.team_Blue .add-team-single-title {
  border-color: blue;
}
.team_Blue .add-team-single-title h3 {
  color: blue;
}

/* ======== */
.team_Red {
  border: 1px solid red;
}
.team_Red .add-team-single-title {
  border-color: red;
}
.team_Red .add-team-single-title h3 {
  color: red;
}

/* ======== */
.team_Yellow {
  border: 1px solid #ffe600;
}
.team_Yellow .add-team-single-title {
  border-color: #ffe600;
}
.team_Yellow .add-team-single-title h3 {
  color: #ffe600;
}

/* ======== */
.team_Green {
  border: 1px solid green;
}
.team_Green .add-team-single-title {
  border-color: green;
}
.team_Green .add-team-single-title h3 {
  color: green;
}

/* ======== */
.team_Orange {
  border: 1px solid orange;
}
.team_Orange .add-team-single-title {
  border-color: orange;
}
.team_Orange .add-team-single-title h3 {
  color: orange;
}
