@media screen and (max-width: 1280px) {
  .login-right-inner-form {
    // width: 100% !important;
    // .login-right-form.card-body {
    //   width: 80%;
    //   margin: auto;
    // }
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    flex-wrap: wrap;
    a {
      padding: 10px 12px !important;
    }
  }
}
@media screen and (max-width: 1024px) {
  .tutorial-single-item {
    padding: 25px 15px !important;
  }
  .card-title.h5 h3 {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .container {
    width: 100%;
    max-width: 100% !important;
  }
  .tutorial-page-title.py-5.market-outlook-page {
    margin-bottom: 0;
    padding-top: 15px !important;
    padding-bottom: 20px !important;
  }
}
