@import "node_modules/react-modal-video/scss/modal-video.scss";
button[disabled] {
  color: gray !important;
  background: #80808021 !important;
}
ul.mb-3.nav.nav-tabs button[disabled] {
  color: unset !important;
  background: unset !important;
}

body {
  table {
    td {
      color: #333 !important;
    }
    th {
      text-transform: inherit !important;
    }
  }
}
.table-inner-header-data-ques {
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: center;
  .network-title-right.round-btn.bg-white-table-data {
    button {
      background: #e8a016;
    }
  }
}
.table-inner-header-data-ques
  .network-title-right.round-btn.bg-white-table-data
  button
  svg
  path {
  fill: #fff;
}
.network-title-right.round-btn.bg-white-table-data button svg path {
  fill: #fff;
}
h3 {
  margin-bottom: 0;
}
td {
  b {
    font-weight: 700;
  }
}
.empty-course-single-team-wrap {
  p {
    i {
      b {
        font-weight: 600;
        font-size: 16px !important;
      }
    }
  }
}

button.btn.header-item.waves-effect.main-profile-right-area {
  display: flex;
  align-items: center;
  gap: 7px;
  text-transform: capitalize;
  img {
    border: 1px solid #000;
  }
}
span.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.tutorial-item-wrap {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  .tutorial-single-item {
    width: 49%;
  }
}
thead.active-game-table-data-header.result-summary-main-header {
  position: sticky;
  top: 76px;
}
// ======================
tr.team_Orange {
  background: #ffdfd4;
  td {
    color: #f5501d !important;
  }
}
// ======================
tr.team_Blue {
  background: #ddeaff;
  td {
    color: #438afe !important;
  }
}
tr.team_Red {
  background: #ffe3e9;
  td {
    color: #fe1743 !important;
  }
}
tr.team_Green {
  background: #ecffed;
  td {
    color: #66bb6a !important;
  }
}
tbody.active-game-table-data-body.ghjhgffghbnvcbnjkk > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: unset !important;
}
//=========================
.marketing-hfbdhbn {
  display: flex;
  width: 100%;
  gap: 20px;
}
.market-share-panning-wrap.income-real-market-share.graph-regional
  > *
  > .estimate-markate-demand-wrap {
  padding-bottom: 20px;
}
//========================================
.login-right-inner-form {
  width: 350px;
  .login-right-form {
    width: 100%;
    .login-right-inner-form-inner-wrap-2 {
      width: 100%;
    }
    .input-field-group {
      width: 100%;
    }
    .password-form-area-show > i {
      position: absolute;
      top: 37px;
      right: 10px;
    }
  }
  &.pass-inline-show > p {
    width: 100%;
    font-size: 12px;
  }
  .input-field-group.password-form-area-show.reset-show {
    position: relative !important;
    width: 100%;
  }
}
//=============================
@media (max-width: 767px) {
  .login-main-wrap {
    .login-left-logo {
      display: none;
    }
    form.login-right-form {
      width: 90%;
      margin: 0 auto;
    }
    .login-right-form {
      width: 100%;
    }
  }
}
