.input-tech-data-area {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  margin-bottom: 50px;
  border: 1px dashed #ddd;
  border-radius: 10px;
  border-width: 2px;
}
.technology-data-single-item-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
.technology-data-single-item-area-right {
  width: 60%;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 5px 10px;
}
.technology-data-single-item-area-left {
  width: 30%;
}
.technology-data-single-item-area-right-edit-btn {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 5px 10px;
}
.single-course-data p {
  margin-bottom: 0;
}
.tech-data-main-wrapper {
  background: #fff;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #dddddd20;
  border-radius: 10px;
  margin-bottom: 30px;
}
