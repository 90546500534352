.error-main-area-hdfskjdfbschdb p {
  color: red;
  display: flex;
  align-items: center;
  gap: 6px;
  i {
    font-size: 18px;
  }
}
.success-message-main-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  text-align: center;
  .success-message-inner-wrap {
    img {
      margin-bottom: 20px;
    }
    h4 {
      margin-bottom: 5px;
    }
    p {
      color: #64748b;
      margin-bottom: 25px;
    }
  }
}
//=============== Issue Fix Css =========================
.password-form-area-show {
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }
}
