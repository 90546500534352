body {
  /* font-family: "Raleway", sans-serif !important; */
  background: #f3f6fa !important;
  font-family: "Sora", sans-serif !important;
  /* background: #f8fbff !important; */
}
body > iframe {
  display: none;
}
p {
  margin-bottom: 0;
}
.text-gradient-amls {
  font-size: 15px;
  background: -webkit-linear-gradient(rgb(15, 135, 81), rgb(37, 135, 30));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h1 {
  font-weight: 700;
  font-family: "Sora", sans-serif !important;
}
h2 {
  font-weight: 700 !important;
  font-family: "Sora", sans-serif !important;
}
h3 {
  font-weight: 700 !important;
  font-family: "Sora", sans-serif !important;
}
h4 {
  font-weight: 700 !important;
  /* font-family: "Poppins", sans-serif !important; */
}
h5 {
  font-weight: 700;
  /* font-family: "Poppins", sans-serif !important; */
}
h6 {
  font-weight: 700;
  /* font-family: "Poppins", sans-serif !important; */
}
a.nav-link {
  font-weight: 500;
}
th {
  font-weight: 600 !important;
}
.calibarX-default {
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: 500;
  background: #1b3f73;
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  /* border: 1px solid transparent; */
}
.calibarX-default:before {
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, red, #7d020294);
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s;
}
.calibarX-default:hover {
  color: #fff;
  /* border: 1px solid; */
}
.calibarX-default:hover:before {
  width: 100%;
  background: #1b3f73;
}

.empty-course-single-team-wrap > .row > .col-lg-6 > .card-body {
  padding: 10px 0;
}
table,
th,
td {
  border: none !important;
}

.network-title-left.marketng-page button:active {
  transform: scale(0.99);
  box-shadow: inset 0px 0px 10px 0px #e8f9fc;
  border: 1px solid transparent;
}

.page-content.admin-home-page-dashboard {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn_blue_team {
  background: blue;
  color: blue;
}
.btn_blue_team-table {
  background: #0000ff26;
  /* color: #fff; */
}
.account-pages.add-courses-admin-desh .form-group {
  display: flex;
}

.account-pages.add-courses-admin-desh .form-group label {
  width: 220px;
}

.feature-data-item-area-wrapper .form-group {
  display: flex;
}

.feature-data-item-area-wrapper .form-group label {
  width: 220px;
}
.feature-data-item-area-wrapper .form-control {
  width: 80%;
}
.feature-data-item-area-wrapper {
  background: #fff;
  margin-bottom: 50px;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #dddddd20;
}
.admin-all-page-header-top-main-title-search-container {
  background: #fff;
  padding: 30px 10px;
  margin-bottom: 50px;
  border-radius: 10px;
}

.inventory-main-area {
  background: #fff;
  margin: 50px 0;
  padding: 30px;
  border-radius: 10px;
}
.inventory-item-area p {
  margin-bottom: 6px;
}
.inventory-item-area-admin {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5vw;
  align-items: center;
}
.inventory-single-item-admin-1 {
  width: 25%;
}
.inventory-single-item-admin-2 {
  width: 25%;
  border: 1px solid #ddd;
  height: 45px;
}
.inventory-single-item-admin-3 {
  width: 47%;
  border: 1px solid #ddd;
}
.inventory-single-item-admin-4 {
  width: 22%;
  border: 1px solid #ddd;
}
.inventory-item-area-admin > * {
  height: 45px;
  padding: 3px 8px;
  border-radius: 6px;
}
.inventory-single-item-admin-2 > select {
  border: none;
}
.inventory-item-area-admin {
  margin-bottom: 15px;
}
.inventory-title {
  margin-bottom: 30px;
}
.inventory-single-item-admin-2.hdyd {
  border: none;
}
.inventory-single-item-admin-3.dbhd {
  border: none;
}
button.admin-save-inventory-btn {
  background: #1b3f73;
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 10px 30px;
}
.nav-link.dropdoun-menu-item {
  position: relative;
}
.nav-link.dropdoun-menu-item > ul {
  position: absolute;
  width: 210px;
  height: auto;
  background: #fff;
  padding-left: 0px;
  visibility: hidden;
  transform-origin: top;
  transition: 0.3s;
  transform: scale(0);
  left: 0;
  z-index: 9;
}
.nav-link.dropdoun-menu-item > ul li {
  list-style: none;
  padding: 10px 20px;
}
.nav-link.dropdoun-menu-item:hover ul {
  visibility: visible;
  transition: 0.3s;
  transform: scale(1);
}
.nav-link.dropdoun-menu-item > ul li:hover {
  background: #e9ecef;
}
.fundamental-round-main-wrap {
  padding: 50px 0;
}
.admin-all-page-header-top-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-all-page-header-top-search > span {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.admin-all-page-header-top-search > span > span {
  position: relative;
  width: 220px;
  height: 40px;
}
.admin-all-page-header-top-search > span > span > input {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border: 1px solid #b5b5b5;
  padding-left: 15px;
}
.technology-data-single-item-area-left.fundamental {
  width: 15%;
}
.technology-data-single-item-area-right.fundamental {
  width: 78%;
  text-align: left;
}
.fundamental-items-main-area {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}
.admin-all-page-header-top-search > span > span > button {
  border: none;
  background: none;
}
.admin-all-page-header-top-search > span > span > button > i {
  position: absolute;
  right: 10px;
  top: 21%;
  font-size: 25px;
}
.empty-teacher-data-page {
  text-align: center;
}
.single-course-data.fundamental > table tr th {
  border: 1px solid #ddd !important;
  font-weight: 600;
  text-align: center;
}
.single-course-data.fundamental > table tr td {
  border: 1px solid #ddd !important;
  text-align: center;
}
.fundamental-items-main-area.outlook-main-item-create {
  width: 500px;
  margin: 70px auto;
}
.create-outlook-body-main-wrap > * > label {
  margin-top: 30px;
}
.outlook-single-button-single-wrap > button {
  border: none;
  background: #1b3f73;
  display: block;
  width: 100%;
  height: 40px;
  color: #fff;
  margin-top: 30px;
  border-radius: 5px;
}
.outlook-single-button-single-wrap.second > button {
  border: none;
  color: #1b3f73;
  display: block;
  width: 100%;
  height: 40px;
  background: #fff;
  margin-top: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.create-outlook-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.single-course-data.fundamental.outlook-page table tr td {
  border: 1px solid #ddd !important;
  text-align: center;
  text-align: left;
}
.single-course-data.fundamental > table tr th:first-child {
  width: 12%;
  text-align: center;
}
.single-course-data.fundamental > table tr th:last-child {
  width: 12%;
  text-align: center;
}
.single-course-data.fundamental > table tr td:first-child {
  width: 12%;
  text-align: center;
}
.single-course-data.fundamental > table tr td:last-child {
  width: 12%;
  text-align: center;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #2b5876 0%,
    #4e4376 51%,
    #2b5876 100%
  );
}
.btn-grad {
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.universe-selected-round-area-rhbnvj {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-right: 50px;
  padding-bottom: 10px;
}
tbody.active-game-table-data-body.ghjhgffghbnvcbnj {
  text-align: center;
}
span.uyghjiouhgduhijxkcn b {
  letter-spacing: 1.5px;
  text-align: center;
  display: block;
  margin-bottom: 5px;
}
button.btn-grad.result-evalution-vhbcjldvbkznj {
  background: unset !important;
  border: none;
  padding: 0;
  text-transform: capitalize;
  border-radius: 5px;
  user-select: none;
  color: #1b3f73;
  box-shadow: none;
  margin-left: 0;
  font-weight: 700;
  font-size: 24px;
  padding-left: 0 !important;
}
button.btn-grad.result-evalution-vhbcjldvbkznj {
  background: #1b3f73;
  border: none;
  padding: 8px 25px;
  text-transform: capitalize;
  border-radius: 5px;
}
.input-field.ghbjnkdfjhbfjnsjkFAG {
  display: flex;
  grid-gap: 0px;
  gap: 0px;
  width: 150px;
}

.input-field.ghbjnkdfjhbfjnsjkFAG > span {
  border: 2px solid #c5c5c5;
  min-width: 65px;
  height: 30px;
  display: block;
  text-align: center;
  border-radius: 5px;
  line-height: 27px;
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  font-size: 13px;
  padding: 0px 5px;
}
.estimate-markate-demand-wrap {
  margin-bottom: 24px;
}

tobody .Production.-requirment-body-main-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Production.-requirment-body-main-wrap {
  width: 100%;
}
tbody {
  width: 100%;
}
.input-field.ghbjnkdfjhbfjnsjkFAG input {
  width: 100%;
  border: 2px solid #c5c5c5 !important;
  border-radius: 5px 0px 0px 5px;
  color: #2f80ed;
  text-align: right;
  padding: 0px 5px;
}
.team-home-data-color-area.card-body h5 {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
}
.team-home-data-color-area.card-body p {
  font-size: 13px;
  margin-bottom: 0;
}
.view-team-area.kjhfkjifughbn img {
  width: 30px;
  height: 30px;
}

.view-team-area.kjhfkjifughbn {
  width: 40px;
  height: 40px;
  background: #4eb94d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.active-group-team.card p {
  margin-bottom: 0;
}
.active-group-team.schedule-group.card {
  grid-gap: 15px;
  gap: 15px;
  padding: 15px 40px !important;
  margin-bottom: 0;
}

.empty-course-content.student-login-page
  > .empty-course-single-team-wrap
  > .row
  > .col-lg-6
  > p
  > i
  > b {
  padding-left: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  display: block;
}
.calibax-pagination > a {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: #fff;
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: 0px 6px 16px 4px #c3c3c330;
  font-weight: 600;
  border: 1px solid #1b3f73;
}
.calibax-pagination > a:hover {
  background: #1b3f73;
  color: #fff !important;
}
.calibax-pagination > * > span {
  display: block;
  line-height: 1;
  position: relative;
  top: 2px;
  font-size: 25px;
}
.calibax-pagination {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 50px;
}
.calibax-pagination > * {
  border: none;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.calibax-pagination > *:first-child {
  color: #1b3f73;
}
.calibax-pagination > *:first-child:hover {
  color: #fff;
  background: #1b3f73;
}
.calibax-pagination > *:last-child {
  background: #1b3f73;
  color: #fff;
  display: flex;
  border: 1px solid transparent;
}
.calibax-pagination > *:last-child:hover {
  color: #1b3f73 !important;
  background: #fff;
  border: 1px solid #1b3f73;
}

select {
  border-radius: 10px !important;
  position: relative;
}
button {
  border-radius: 10px !important;
  font-family: "Sora" !important;
}

.not-submit-color button {
  border-radius: 10px !important;
  font-family: "Sora" !important;
}
.network-title-right.round-btn.result-page select {
  cursor: pointer;
  width: 155px;
  text-align: center;
  padding: 8px 25px;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
.result_summary-share {
  display: flex;
  gap: 5px;
}
.result_summary-top-share {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.result_summary-share p {
  margin-bottom: 0;
  padding: 0px 5px;
  position: relative;
  top: -2px;
}

select {
  border-radius: 10px !important;
  position: relative;
}
button {
  border-radius: 10px !important;
  font-family: "Sora" !important;
}
.not-submit-color button {
  border-radius: 10px !important;
  font-family: "Sora" !important;
}
.network-title-right.round-btn.result-page select {
  cursor: pointer;
  width: 155px;
  text-align: center;
  padding: 8px 25px;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
.result_summary-share {
  display: flex;
  gap: 5px;
}
.result_summary-top-share {
  display: flex;
  gap: 5px;
}
.result_summary-share p {
  margin-bottom: 0;
  padding: 0px 5px;
  position: relative;
  top: -2px;
}
.input-file-wrap label {
  cursor: pointer;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #ecf3fd !important;
}
.empty-course-content-left table {
  vertical-align: middle !important;
}
.empty-course-content-left table tbody {
  padding: 20px 0;

  border-top: none !important;
}
.empty-course-content-left table tbody {
  padding: 0px 0;
  border-top: none !important;
  vertical-align: middle;
}
.empty-course-content-left table thead {
  padding: 15px 5px;
  display: block;
  background: #1b3f73;
}
.empty-course-content-left table thead tr th {
  color: #fff !important;
}
/* .empty-course-content-left table tbody tr td {
} */
tr.row {
  align-items: center;
  height: 100%;
  /* padding: 10px; */
}
tr.row > .col-4:last-child {
  padding: 11px;
  display: block;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  padding: 10px 10px;
}
.table-striped > tbody > tr:nth-of-type(even) > * {
  padding: 10px 10px;
}
.empty-course-single-item {
  padding-bottom: 0;
}
.empty-course-content-left table {
  margin-bottom: 0;
}
button.techActiveColor {
  background-color: #1b3f73 !important;
  color: white !important;
}

.input-field-group.password-form-area-show.reset-show {
  position: relative !important;
}

.input-field-group.password-form-area-show.reset-show > i {
  position: absolute !important;
  top: 9px !important;
}

.login-right-inner-form.pass-inline-show > p {
  width: 69%;
}

button.market-share-planning-select-tech.result-summary-page.btn.btn-primary {
  margin: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fff !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffffff !important;
}
.empty-course-content.schedule-group-wrap {
  padding-bottom: 2%;
}
