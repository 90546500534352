.login-main-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.login-left-logo {
  width: 50%;
  height: 100%;
  background: #1b3f73;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-right-form {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0%;
}

.login-right-inner-form h2 {
  font-size: 40px;
  margin-bottom: 25px;
}

.input-field-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /* width: 270px; */
}

.input-radio-group {
  background: #1b3f73;
  width: 64px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.input-radio-group input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0px;
  background-clip: content-box;
  border: 2px solid #1b3f73;
  background-color: #1b3f73;
  border-radius: 50%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.input-radio-group input[type="radio"]:checked {
  background-color: #fff;
}

.input-field-group input {
  width: 100%;
  border: none;
  background: #eff0f6;
  padding: 7px 10px;
  border-radius: 5px;
}

.input-field-group button {
  width: 100%;
  text-align: center;
  background: #1b3f73;
  color: #fff;
  padding: 10px 30px;
  font-weight: 700;
  border: none;
  border-radius: 5px;
}

.input-field-group input::placeholder {
  font-size: 14px;
}

.submit-btn {
  margin-top: 10px;
}

.input-field-group p {
  font-size: 16px;
}
.input-field-group.submit-btn.mb-3.verify-email-page {
  width: 100%;
}
