.material-main-wrap {
  padding: 70px 0;
  /* background: #e5e5e5; */
}

.material-title {
  margin-bottom: 40px;
}

.material-title h3 {
  font-size: 36px;
  color: #1b3f73;
  font-weight: 600;
}

.material-single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
  padding: 0px 0px;
  margin-bottom: 15px;
}

.material-left-content {
  background: #fff;
  border-radius: 10px !important;
  padding: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.material-single-item-left h6 {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 16px;
}

.material-single-item-left p {
  font-size: 12px;
  color: #828282;
}

.material-left-view-btn.btn.btn-primary {
  background: #1b3f73;
  border-color: #1b3f73;
  font-size: 14px;
  width: 100px;
}

.material-left-content > h4 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 30px;
}

.material-single-item:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 5px;
}

.material-video-player {
  width: 100% !important;
  height: 284px !important;
  border-radius: 10px !important;
  overflow: hidden;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
