.empty-course-main-wrap {
  padding: 50px 0;
  /* background: #E5E5E5; */
}

.empty-course-single-item {
  background: #fff;
  border-radius: 10px;
  padding: 30px 0;
  padding-top: 0;
  overflow: hidden;
  margin-bottom: 25px;
  box-shadow: 0px 0px 10px 0px #b6b6b615;
  border: 1px solid #f8f9fa;
}
/* .empty-course-single-item {
  background: #fff;
  border-radius: 10px;
  padding: 30px 0;
  padding-top: 0;
  overflow: hidden;
  margin-bottom: 25px;
  box-shadow: 0 0 10px 0 #e2e2e2b5;
} */
.empty-course-content-left table {
  width: 100%;
  text-align: center;
  border: none;
}

.empty-course-content-left td {
  border: none;
}

.empty-course-content-left th {
  border: none;
}

.empty-course-content-left table thead {
  padding: 15px 5px;
  display: block;
  background: #f8f9fa;
}

.empty-course-content-left table thead tr th {
  color: #1b3f73;
  font-size: 16px;
}

.empty-course-content-left table tbody {
  padding: 20px 0;
  display: block;
}

.empty-course-title {
  background: #1b3f73;
  padding: 20px 50px;
  padding-left: 40px;
}

.empty-course-title h2 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
}

.empty-course-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
}

button.all-submit-btn.btn.btn-primary {
  background: #1b3f73;
  padding: 8px 30px;
}

button.all-submit-btn-2.btn.btn-primary {
  background: #fff;
  border: 2px solid #1b3f73;
  padding: 8px 25px;
  color: #1b3f73;
}

.empty-course-single-team-wrap {
  width: 100%;
  margin-bottom: 40px;
}

.empty-course-single-team {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;
}

.empty-course-content.login-page {
  padding: 2% 10%;
  display: block;
}

.empty-course-single-team-title {
  padding-bottom: 0px;
  border-bottom: 2px solid #ddd;
  margin-bottom: 7px;
}

.empty-course-single-team-names > i {
  margin-bottom: 20px;
  display: block;
  color: #95989b;
}

.active-group-team.card {
  flex-direction: unset;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-radius: unset;
  border-left: unset;
  border-top: unset;
  border-right: unset;
  margin-bottom: 10px;
}

img.active-group-card-image {
  width: 40px;
  height: 40px;
  background-color: #ddd;
  border-radius: 50%;
}

.empty-course-content.login-active {
  padding: 0% 7%;
  width: 100%;
  justify-content: space-between;
  display: block;
}

.active-group-team.card h4 {
  font-size: 18px;
  margin-bottom: 0px;
}

.active-group-team.card i {
  font-size: 14px;
}

.empty-course-content.schedule-group-wrap {
  display: block;
  /* padding: 0% 8%; */
  width: 100%;

  /* overflow-y: scroll; */
}

.active-group-team.schedule-group.card {
  margin-bottom: 15px;
  padding: 10px 0;
}

.active-group-team.schedule-group.card .card-body {
  display: flex;
  justify-content: space-between;
}
.active-group-team.schedule-group.card {
  padding: 15px 40px;
}

.empty-course-content.schedule-group-wrap::-webkit-scrollbar {
  width: 10px;
}
.empty-course-content.schedule-group-wrap::-webkit-scrollbar-thumb {
  background: #888;
}
.empty-course-content.schedule-group-wrap::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.empty-course-content.schedule-group-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.questionMark.dropdown-item {
  width: 200px;
  height: 100px;
  display: block;
}
.questionMark {
  width: 300px !important;
  /* min-height: 100px !important; */
  color: #1b3f73 !important;
  background: #edf6ff;
  font-size: 13px !important;
  display: block !important;
  text-align: left;
  padding: 15px;
  font-weight: 400 !important;
  line-height: 23px;
  text-transform: initial;
  padding-left: 20px;
}
